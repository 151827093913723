::-webkit-scrollbar, iframe body::-webkit-scrollbar, .body-inside-iframe::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
  background-color: transparent !important;
  /*border: 1px solid #d5d5d5 !important;*/
}

::-webkit-scrollbar-thumb, iframe body::-webkit-scrollbar-thumb, .body-inside-iframe::-webkit-scrollbar-thumb {
  background-color: var(--border-color) !important;
  border: 2px solid var(--border-color) !important;
}

* {
  /*outline: 1px solid green;*/
}
html {
  scroll-behavior: smooth;
  /*overflow: hidden;*/
  overflow-x: hidden;
}

body {
  position: relative;
  width: 100vw;
  color: var(--text-color);
  font-family: var(--font-refular);
  font-size: 18px;
  line-height: 24px;
  background-color: var(--main-bg-color);
  margin: 0;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100vw;
  padding-top: 160px;

}
.main-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
}
.no-scroll {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--text-color);
}

h1 {
  position: relative;
  text-wrap: balance;
  text-align: center;
  font-size: clamp(24px, 4vw, 56px);
  /*line-height: 56px;*/
  line-height: clamp(36px, 4vw, 76px);
  margin: 0 auto;
  max-width: 1120px;
  font-family: var(--font-bold);
}
h1 .text-tint {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 199, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  padding: 6px 32px 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: rotate(2deg);
}

h2 {
  font-size: clamp(36px, 4vw, 48px);
  line-height: clamp(40px, 4vw, 56px);
  margin-bottom: clamp(32px, 4vw, 48px);
  font-family: var(--font-bold);
  text-align: center;
}

h3 {
  font-size: 24px;
  line-height: 28px;
  font-family: var(--font-bold);
}

h4 {
  font-size: 18px;
  line-height: 24px;
  font-family: var(--font-bold);
}

.btn {
  outline: none;
  height: 48px;
  border-radius: 24px;
  transition: var(--transition);
  font-family: var(--font-medium);
  position: relative;
  font-size: 16px;
  line-height: 22px;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  width: auto;
  box-shadow: none;
}

.btn:active {
  box-shadow: none;
}
.btn .btn-spinner {
  position: absolute;
  align-self: center;
  justify-content: center;
  display: flex;
  left: 0;
  right: 0;
}
.btn.loading {
  filter: grayscale(1);
  opacity: 0.5;
}
.btn-lg {
  height: 64px;
  font-size: 18px;
  line-height: 28px;
  -webkit-border-radius: 80px;
  -moz-border-radius: 80px;
  border-radius: 80px;
}
.btn:has(.btn-primary-icon) {
  padding-left: 40px;
}

.btn-primary {
  box-shadow: none;
  background-color: var(--tint-color);
  border: none;
  color: var(--main-bg-color);
}
.btn-primary:focus {
  box-shadow: none;
  background-color: var(--tint-color);
  color: var(--main-bg-color);
}

.btn-primary:active:focus {
  border-color: #DCAC03;
  background-color: #DCAC03;
  box-shadow: none;
}


.btn-primary:hover {
  background-color: var(--tint-color);
  color: var(--main-bg-color);
  box-shadow: 0px 0px 20px rgba(255, 199, 0, 0.8);
}
.btn.btn-primary.disabled{
  pointer-events: none;
  background-color: var(--main-bg-color);
  color: var(--secondary-bg-color);
}


.btn-primary-icon {
  width: 56px;
  height: 56px;
  background-color: var(--main-bg-color);
  box-shadow: inset 0px 0px 20px rgba(255, 199, 0, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -20px;
}


.btn-secondary {
  box-shadow: none;
  background-color: #fff!important;
  border: none;
  color: var(--main-bg-color);
}
.btn-secondary:focus {
  box-shadow: none !important;
  background-color: var(--tint-color);
  color: var(--main-bg-color);
}

.btn-secondary:active:focus {
  border-color: #E8E8E8!important;
  background-color: #E8E8E8 !important;
  box-shadow: none;
}


.btn-secondary:hover {
  background-color: #F3F3F3 !important;
  color: var(--main-bg-color);
  box-shadow: none;
}
.btn.btn-secondary.disabled{
  pointer-events: none;
  background-color: var(--main-bg-color);
  color: var(--secondary-bg-color);
}

.btn-outline-primary {
  color: var(--tint-color);
  box-shadow: none;
  border: 1px solid var(--tint-color);
  background-color: transparent;
}

.btn-outline-primary:focus {
  box-shadow: none;
  color: var(--tint-color);
  border-color: var(--tint-color);
  background-color: transparent;
}

.btn-outline-primary:active:focus {
  color: var(--tint-color);
  border-color: var(--tint-color);
  background-color: rgba(255, 199, 0, .05);
  box-shadow: none;
}


.btn-outline-primary:hover {
  color: var(--tint-color);
  border-color: var(--tint-color);
  background-color: rgba(255, 199, 0, .1)
}


.btn.btn-outline-primary:disabled {
  pointer-events: none;
  background-color: transparent;
  color: var(--secondary-bg-color);
  border-color: var(--secondary-bg-color);
}













.btn-outline-secondary {
  color: #fff;
  box-shadow: none;
  border: 1px solid #fff;
  background-color: transparent;
}

.btn-outline-secondary:focus {
  color: #fff;
  box-shadow: none;
  border-color: #fff;
  background-color: transparent;
}

.btn-outline-secondary:active:focus {
  color: #fff;
  border-color: #fff;
  background-color: rgba(255, 255, 255, .05);
  box-shadow: none;
}


.btn-outline-secondary:hover {
  color: #fff;
  border-color: #fff;
  background-color: rgba(255, 255, 255, .15);
}

.btn.btn-outline-secondary:disabled {
  pointer-events: none;
  background-color: transparent;
  color: var(--secondary-bg-color);
  border-color: var(--secondary-bg-color);
}


ul {
  margin: 0;
  padding-left: 20px;
}

ul li {
  padding-bottom: 8px;
}

p {
  margin-bottom: 0;
  letter-spacing: 0.01em;
}

a {
  color: #464646;
  text-decoration: none;
  outline: none;
  transition: var(--transition);
  font-size: 16px;
  line-height: 20px;
}

a:hover {
  outline: none;
  color: var(--text-color);
}

a:active {
  outline: none;
  color: var(--tint-color);
}

.text-bold {
  font-family: var(--font-bold);
}

.text-bold-16 {
  font-family: var(--font-bold);
  font-size: 16px;
  line-height: 24px;
}

.text-muted {
  color: var(--muted-color) !important;
}

.text-medium {
  font-family: var(--font-medium);
}

.text-muted-14 {
  color: var(--muted-color) !important;
  font-size: 14px;
  line-height: 18px;
}

.text-16 {
  font-size: 16px;
  line-height: 20px;
}

.text-14 {
  font-size: 14px;
  line-height: 18px;
}

.text-12 {
  font-size: 12px;
  line-height: 18px;
}

.text-tint {
  color: var(--tint-color);
}

.cur-pointer {
  cursor: pointer;
}

section {
  padding-bottom: 80px;
  position: relative;
}

section:last-child {
  border-bottom: none;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-80 {
  margin-bottom: 80px;
}

.Toastify__toast-theme--light {
  background: var(--main-bg-color);
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-family: var(--font-medium);
  display: flex;
  align-items: center;
  border-radius: 0;
  border: 1px solid #43454B;
}

.Toastify__progress-bar--error {
  background: var(--danger);
}

.react-icon > div {
  display: flex;
  align-items: center;
  justify-content: center;
}


.ps-20 {
  padding-left: 20px;
}

.pe-20 {
  padding-right: 20px;
}

.overflow-x-hidden {
  overflow: hidden;
  width: 100vw;
}
.rotate-danger-text {
  color: var(--danger);
  height: 62px;
  padding: 0 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--danger);
  border-radius: 12px;
  transform: rotate(-5deg);
  font-size: 20px;
  line-height: 24px;
  font-family: var(--font-bold);
  white-space: nowrap;
}

.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}



.terms-link {
  font-size: 14px;
  color: #464646;
  text-decoration: underline;
  cursor: pointer;
  transition: var(--transition);
}
.terms-link:hover {
  color: var(--muted-color);
}

.terms-modal {
  background-color: var(--main-bg-color);
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  width: calc(100% - 40px);
}
.terms-modal a {
  color: var(--tint-color);
  text-decoration: underline;
}
.terms-modal p {
  margin-bottom: 8px;
}
.terms-modal h2, .terms-modal h3, .terms-modal h4 {
  margin-bottom: 24px;
  margin-top: 8px;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  font-size: 14px;
  padding-left: 40px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid var(--tint-color);
  border-radius: 100%;
  background: var(--tint-color);
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: var(--main-bg-color);
  position: absolute;
  top: 7px;
  left: 7px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}


.right-col {
  width: 453px;
  min-width: 453px;
  margin-left: 80px;
  position: relative;
}

.circle-bg {
  position: absolute;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  border-radius: 50%;
}
.circle-bg.red {
  background-color: var(--danger);

}
.circle-bg.yellow {
  background-color: var(--tint-color);

}
.circle-bg-red{
  position: absolute;
  pointer-events: none;
  background: url("../images/bg-red.png") no-repeat center center/ 100%;
}
.circle-bg-yellow {
  background: url("../images/bg-yellow.png") no-repeat center center/ 100%;
  position: absolute;
  pointer-events: none;
}






.react-select-container .react-select__control {
  background: var(--main-bg-color);
  border: 1px solid rgba(255, 255, 255, 0.2);
  min-height: 50px;
  border-radius: 12px;
  box-shadow: none;


}
.react-select-container .react-select__control:hover {
   border-color: rgba(255, 255, 255, 0.2);
 }
.react-select-container .react-select__value-container {
  padding-left: 2px;
}

.react-select-container .react-select__input-container {
  padding-left: 14px;
  color: #fff;
}

.react-select-container .react-select__placeholder {
  padding-left: 14px;
  font-family: var(--font-refular) !important;
  color: rgba(255, 255, 255, 0.3);
  font-size: 16px;
}

.react-select-container .react-select__control--menu-is-open {
  background: var(--main-bg-color);
  border-color: rgba(255, 255, 255, 0.2);
  min-height: 50px;
  border-radius: 12px 12px 0 0;
  box-shadow: none;
}

.react-select-container .react-select__indicator-separator {
  display: none;
}

.react-select-container .react-select__clear-indicator {
  margin-right: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url("../../assets/images/icons/close.svg") no-repeat center center/ 24px;


}
.react-select-container .react-select__clear-indicator svg {
  display: none;
}
.react-select__dropdown-indicator {
  margin-right: 16px;
  background: url("../../assets/images/icons/caret.svg") no-repeat center center;
}
.react-select-container .react-select__dropdown-indicator svg {
  display: none;
}
.react-select-container .react-select__multi-value {
  background: rgba(255, 255, 255, 0.1);
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 6px;
  padding-right: 8px;
}

.react-select-container .react-select__multi-value__label {
  color: #fff;
  font-size: 16px;
  padding-right: 8px;
}

.react-select-container .react-select__multi-value__remove {
  width: 24px;
  height: 24px;
  -webkit-border-radius: 48px;
  -moz-border-radius: 48px;
  border-radius: 48px;
  background: url("../../assets/images/icons/close.svg") no-repeat center center/ 24px, rgba(255, 255, 255, 0.1);
  transition: var(--transition);


}
.react-select-container .react-select__multi-value__remove svg {
  display: none;
}

.react-select-container .react-select__multi-value__remove:hover {
   background: url("../../assets/images/icons/close.svg") no-repeat center center/ 24px, rgba(255, 255, 255, 0.2);
   color: #fff;
 }
.react-select-container .react-select__menu {
  background: var(--main-bg-color);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-top: 0;
  border-radius: 0 0 12px 12px;
  top: calc(100% - 8px);
  overflow: hidden;
}

.react-select-container .react-select__menu-list {
  max-height: 200px;
  padding-bottom: 0;

}

.react-select-container .react-select__option {
  background: transparent;
  height: auto;
  padding: 0 16px;
  margin: 8px 0;
  font-size: 14px;


}
.react-select-container .react-select__option:hover {
   background: transparent;
 }
.react-select-container .react-select__option-content {
  cursor: pointer;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
  height: 32px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  color: var(--muted-color);


}
.react-select-container .react-select__option-content:hover {
   color: #fff;
   background: url("../../assets/images/icons/add_white.svg") no-repeat 6px center, rgba(255, 255, 255, 0.15);
 }

.react-select-container .react-select__option--is-selected .react-select__option-content {
  background: url("../../assets/images/icons/done_tint.svg") no-repeat 6px center, rgba(255, 199, 0, 0.15);
  color: #fff;
  border-color: var(--tint-color);
}
