.section-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-3-item-text p {
  font-size: 16px;
  line-height: 24px;
}

.section-3-item {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding-top: 64px;
  position: relative;
}

.section-3-item:last-child {
  padding-top: 0;
}

.section-3-item:nth-child(2n) {
  flex-direction: row;
}

.section-3-item-separator-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 32px;
  position: relative;
  align-self: normal;
}

.section-3-item-separator-wrap:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 50%;
  background-color: #2B2B2B;
  top: 0;
  z-index: -1;
  transition: var(--transition);
}

.section-3-item:last-child .section-3-item-separator-wrap:before {
  display: none;
}

.section-3-item-separator-wrap:after {
  content: '';
  position: absolute;
  width: 2px;
  height: calc(50% + 64px);
  background-color: #2B2B2B;
  bottom: -64px;
  z-index: -1;
  transition: var(--transition);
}

.section-3-item:first-child .section-3-item-separator-wrap:after {
  display: none;
}

.section-3-item.active .section-3-item-separator-wrap:before {
  background-color: var(--tint-color);
}

.section-3-item.active + .section-3-item .section-3-item-separator-wrap:after {
  background-color: var(--tint-color);
}

.section-3-item-separator {
  min-width: 80px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #2B2B2B;
  transition: var(--transition);
  background-color: var(--main-bg-color) !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 40px !important;
}

.section-3-item-separator:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 49px;
  background-color: var(--tint-color);
  top: 100%;
  opacity: 0;
  transition: var(--transition);
}

.section-3-item-separator:before {
  content: '';
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--tint-color);
  z-index: -1;
  filter: blur(80px);
  opacity: 0;
  transition: var(--transition);
  right: -60px;
}

.section-3-item:nth-child(2n) .section-3-item-separator:before {
  right: auto;
  left: -60px;
}

.section-3-item.active .section-3-item-separator:after, .section-3-item.active .section-3-item-separator:before {
  opacity: 1;
}

.section-3-item.active .section-3-item-separator {
  border-color: var(--tint-color);
}

.section-3-item:nth-child(4) .section-3-item-separator {
  background: url("./images/fire.svg") no-repeat center center;
}

.section-3-item.active:nth-child(4) .section-3-item-separator {
  background: url("./images/fire_active.svg") no-repeat center center, var(--main-bg-color);
}

.section-3-item:nth-child(3) .section-3-item-separator {
  background: url("./images/card.svg") no-repeat center center;
}

.section-3-item.active:nth-child(3) .section-3-item-separator {
  background: url("./images/card_active.svg") no-repeat center center, var(--main-bg-color);
}

.section-3-item:nth-child(2) .section-3-item-separator {
  background: url("./images/star.svg") no-repeat center center;
}

.section-3-item.active:nth-child(2) .section-3-item-separator {
  background: url("./images/star_active.svg") no-repeat center center, var(--main-bg-color);
}

.section-3-item:nth-child(1) .section-3-item-separator {
  background: url("./images/question.svg") no-repeat center center;
}

.section-3-item.active:nth-child(1) .section-3-item-separator {
  background: url("./images/question_active.svg") no-repeat center center, var(--main-bg-color);
}

.section-3-item-screen {
  flex: 1;
  border-radius: 32px;
  overflow: hidden;
}

.section-3-item-screen img {
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  border-radius: 32px;

}

.section-3-item-text {
  flex: 1;
}

.section-3-item-text-content {
  padding-right: 120px;
}

.section-3-item:nth-child(2n) .section-3-item-text-content {
  padding-right: 0;
  padding-left: 120px;
}

.section-3-item-footer {
  flex-direction: row;
  border-radius: 40px;
  margin-top: 64px;
  width: 100%;
  position: relative;
  justify-content: center;
}

.section-3-item-footer:before {
  content: '';
  position: absolute;
  width: 72px;
  height: 72px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: var(--tint-color);
  top: 1px;
  left: 100px;
  z-index: -1;
}

.section-3-item-footer:after {
  content: '';
  position: absolute;
  width: 72px;
  height: 72px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: var(--danger);
  top: 10px;
  right: 300px;
  z-index: -1;
}
.section-3-item-footer-line {
  position: absolute;
  width: 2px;
  height: 300px;
  background-color: #2B2B2B;
  bottom: 100%;
  z-index: -2;
  transition: var(--transition);
}
.section-3-item-footer-bg {
  position: absolute;
  width: 72px;
  height: 72px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: var(--tint-color);
  filter: blur(40px);
  top: -30px;
  z-index: -1;
  transition: var(--transition);
  opacity: 0;
}
.section-3-item-footer-content {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(60px);
  border-radius: 40px;
  height: 88px;
  padding: 0 12px;
}
.section-3-item-footer.active .section-3-item-footer-bg {
  opacity: 1;
}
.section-3-item-footer.active .section-3-item-footer-line {
  background-color: var(--tint-color);
}

@media only screen and (max-width: 991px) {
  .section-3-item-text-content {
    padding: 0 !important;
  }

  .section-3-item-screen {
    border-radius: 24px;
  }

  .section-3-item-screen img {
    border-radius: 24px;
  }

  .section-3-item-separator {
    width: 64px;
    height: 64px;
    min-width: 64px;
    background-size: 32px !important;
  }

  .section-3-item {
    padding-top: 32px;
  }

  .section-3-item-separator-wrap:after {
    height: calc(50% + 32px);
    bottom: -32px;
  }

}

@media only screen and (max-width: 767px) {
  .section-3 h2 {
    margin-bottom: 0;
  }

  .section-3-item {
    flex-direction: column !important;
    padding-top: 32px !important;
    padding-left: 25px;
  }

  .section-3-item-screen {
    margin-bottom: 16px;
  }

  .section-3-item h3 {
    margin-bottom: 8px;
    padding: 0 16px !important;
    text-align: center;
  }

  .section-3-item p {
    margin-top: 0 !important;
    font-size: 14px;
    line-height: 20px;
  }

  .section-3-item-separator-wrap {
    position: absolute;
    left: -47px;
    margin: 0;
    top: 0;
    bottom: 0;
    align-items: flex-start;
    padding-top: 64px;
  }

  .section-3-item-separator:after {
    top: 62px;
    height: 82px;
  }

  .section-3-item-footer {
    padding: 0 !important;
    margin-top: 32px;
  }

  .section-3-item-footer-content {
    flex-direction: column;
    padding: 16px;
    height: auto;
  }

  .section-3-item-footer-content:before {
    display: none;
  }

  .section-3-item-footer-content .btn {
    width: 100%;
    margin-top: 24px;
    min-width: 100% !important;
  }

  .section-3-item:first-child .section-3-item-separator:after {
    display: none;
  }

  .section-3-item:first-child .section-3-item-separator-wrap:before {
    height: 64px;
  }

  .section-3-item-footer:after {
    left: auto;
    right: 1px;
  }

  .section-3-item-separator {
    background-size: 20px !important;
    background-position: 32px center !important;
  }
  .section-3-item-footer-line, .section-3-item-footer-bg {
    display: none;
  }
}