.section-2-item-wrap {
  width: 50%;
  min-width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  height: 277px;
}

.section-2-item-wrap:before {
  content: '';
  position: absolute;
  width: 160px;
  height: 160px;
  opacity: 0.5;
  top: 2px;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--tint-color);
}

.section-2-item-wrap:first-child {
  padding-right: 12px;
}

.section-2-item-wrap:last-child {
  padding-left: 12px;
  margin-top: 88px;
}

.section-2-item-wrap:last-child:before {
  background-color: var(--danger);
}

.section-2-item {
  padding: 8px;
  border-radius: 32px;
  background: rgba(27, 27, 27, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(60px);
  backdrop-filter: blur(80px);
  display: flex;
  padding-right: 40px;
}

.section-2-item p {
  font-size: 16px;
  line-height: 24px;
}

.section-2-item-channel {
  position: absolute;
  left: 8px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none !important;
}


.section-2-item-channel .react-icon {
  margin-left: 4px;
  opacity: 0;
  transition: var(--transition);
}

.section-2-item-channel img {
  width: 48px;
  height: 48px;
  min-width: 48px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.section-2-item-channel h4 {
  font-size: 14px;
  margin: 0;
  margin-left: 8px;
  line-height: 18px;
  transition: var(--transition);
  width: 66px;
}
.section-2-item-channel:hover h4 {
  color: var(--tint-color);
}
.section-2-item:hover .section-2-item-channel .react-icon {
  opacity: 1;
}
.section-2-item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 0;
}

.section-2-item-video {
  min-width: 186px;
  width: 186px;
  height: 100%;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  margin-right: 40px;
  display: flex;
  align-items: center;
}

.section-2-item-video:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 72px;
  background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.9) 100%);

}

.section-2-item-video:after {
  content: '';
  position: absolute;
  inset: 0;
  border: 2px solid var(--tint-color);
  border-radius: 24px;
  pointer-events: none;
  opacity: 0;
  transition: var(--transition);
}

.section-2-item-video:hover:after, .section-2-item-video.active:after {
  opacity: 1;
}

.section-2-item-video video {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.btn.btn-expand {
  background: rgba(20, 20, 20, 0.4);
  padding: 0;
  position: absolute;
  width: 48px;
  top: 8px;
  right: 8px;
  box-shadow: none !important;
}

.btn.btn-expand:hover {
  background: rgba(20, 20, 20, 0.6);
}

.btn.section-2-item-play {
  background: rgba(20, 20, 20, 0.4);
  padding: 0;
  z-index: 1;
  position: absolute;
  width: 48px;
  top: 8px;
  left: 8px;
  box-shadow: none !important;
}

.section-2-item-play svg path, .section-2-item-play svg rect {
  transition: var(--transition);
}

.btn.section-2-item-play:hover {
  background: var(--tint-color);
}

.btn.section-2-item-play:hover svg path, .btn.section-2-item-play:hover svg rect {
  fill: var(--main-bg-color);
}

.video-modal .section-2-item-video {
  margin: 0;
  width: auto;
  height: auto;
  overflow: hidden;
  min-width: auto;

}

.video-modal .section-2-item-video > div {
  width: 100% !important;
  height: 100%;

}

.video-modal .section-2-item-video video {
  object-fit: contain;
}

.video-modal {
  background-color: transparent !important;
  padding: 0 !important;
}

.section-2-item-video.apple .btn-expand {
  display: none;
}

@media only screen and (max-width: 1279px) {
  .section-2-item-wrap {
    height: 265px;
  }

  .section-2-item {
    padding-right: 16px;
  }

  .section-2-item-video {
    margin-right: 24px;
  }
}

@media only screen and (max-width: 991px) {
  .section-2-item-wrap {
    width: 100%;
    padding: 0 !important;
    height: auto;
  }

  .section-2-item-wrap:last-child {
    margin-top: 24px;
  }

  .section-2-item-video {
    width: 250px;
    min-width: 250px;
  }
}

@media only screen and (max-width: 575px) {


  .section-2-item {
    padding: 8px;
    flex-direction: column-reverse;
  }

  .section-2-item-text {
    padding: 16px;
  }

  .section-2-item p {
    font-size: 14px;
    line-height: 20px;
  }
}