footer {
  padding: 0 40px;
  height: 100px;
  width: 100%;
  background: #000000;
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-logo {
  width: 224px;
  min-width: 224px;
}
@media only screen and (max-width: 991px) {
  footer {
    height: auto;
  }
  .footer {
    padding-bottom: 20px;
    padding-top: 20px;
    flex-direction: column;
    max-width: 100%;
    width: 400px!important;
  }
  .footer-logo {
    align-self: flex-start;
    margin-bottom: 16px;
  }

}
@media only screen and (max-width: 767px) {
  footer {
    padding: 0 16px;
  }
}