@media only screen and (max-width: 1279px) {
  header {
    padding: 20px 0;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }


  .header-left {
    margin-bottom: 24px;
    margin-right: 0;
    width: 100%;
  }

  .header-menu {
    order: 1;
    display: none;
  }

  .header-right {
    order: 0;
  }

  .header-right .btn-outline-secondary {
    margin-left: 8px;
  }
  .header-lang-select {
    margin-left: 8px;
  }
  nav {
    margin: 0 -12px;
  }

  .menu-burger {
    display: block;

  }
  .hidden-menu {
    display: flex;
  }
}

@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 767px) {


  .header-secondary {
    flex-direction: column !important;
  }
  .header-secondary .header-right {
    margin-top: 16px;
  }

}

@media only screen and (max-width: 575px) {
  .header-right > * {
    margin-left: 8px;
  }
  .header-right > *:first-child {
    margin-left: 0;
  }
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .menu-burger {

  }
  .header-right {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 374px) {

}