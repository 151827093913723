.section-4:before {
  content: '';
  position: absolute;
  width: 296px;
  height: 184px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: 135px;
  left: 61px;
  z-index: -1;
  background-color: #fff;
  opacity: 0.5;
}

.section-4:after {
  content: '';
  position: absolute;
  width: 280px;
  height: 200px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: 20px;
  left: 230px;
  z-index: -1;
  background-color: var(--danger);
  opacity: 0.5;

}

.section-4-content {
  padding: 56px 32px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(27, 27, 27, 0.2);
  backdrop-filter: blur(60px);
  border-radius: 32px;
}

.section-4 h2 {
  text-wrap: balance;
  margin-bottom: 48px;
}

.section-4-footer {
  display: flex;
  align-items: center;
}

.section-4-footer-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 50px;
}

.section-4-footer-img {
  flex: 1;
  padding-left: 100px;
}

.section-4-footer-img img {
  width: 100%;
  border: 1px solid rgba(255,255,255,0.05);
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
}
.section-4-footer-text .btn {
  min-width: 294px;
}

@media only screen and (max-width: 991px) {
  .section-4-content {
    padding: 24px;
  }

  .section-4-footer-text {
    padding: 0 16px;
  }

  .section-4-footer-img {
    padding-left: 32px;
  }
  .section-4 h2 {
    margin-bottom: 32px;
  }
  .section-4-footer-text .btn {
    min-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .section-4-footer-text {
    padding: 0;
  }

  .section-4-footer-text p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .section-4-content {
    padding: 8px;
    padding-top: 24px;
  }
  .section-4-footer {
    flex-direction: column;
  }
  .section-4-footer-text {
    padding: 0 24px;
  }
  .section-4-footer-img {
    padding-left: 0;
    margin-top: 24px;
  }
  .section-4:before {
    left: 10px;
  }
  .section-4:after {
    left: auto;
    right: 10px;
  }
  .section-4:before {
    left: 1px;
    width: 80%;
  }
  .section-4:after {
    left: auto;
    right: 1px;
    width: 40%;
  }
}