@media only screen and (max-width: 1550px) {

}

@media only screen and (max-width: 1279px) {
  section {
    padding-bottom: 60px;
  }
  h3 {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 28px;
  }

  h4 {
    font-size: 18px;
    line-height: 28px;
  }


  body {
    font-size: 16px;
    line-height: 22px;
    overflow-x: hidden;
  }

  .mb-80 {
    margin-bottom: 40px;
  }

  .mb-40 {
    margin-bottom: 20px;
  }

  .mb-20 {
    margin-bottom: 16px;
  }

}

@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 767px) {
  h1 .text-tint {
    padding-top: 4px;
    padding-bottom: 6px;
  }

  main {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 575px) {
  main {
    padding-left: 4px;
    padding-right: 4px;
  }
  .btn:has(.btn-primary-icon) {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 374px) {

}
