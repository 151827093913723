.section-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-smile {
  background: url("./smile.svg") no-repeat center center;
  width: 36px;
  height: 36px;
  display: inline-flex;
  background-size: 150%;
  position: relative;
  top: 5px;
}

.section-1-text {
  text-align: center;
  margin-top: 24px;
  color: var(--muted-color);
  text-wrap: balance;
}

.page-title-text span {
  position: relative;
  z-index: 1;
}


.section-1-btn {
  margin: 48px auto 0;
  min-width: 294px;
  display: flex;
  align-items: center;
  justify-content: center;

}


.section-1-footer-wrap {
  margin-top: 80px;
  position: relative;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-1-footer-bg {
  position: absolute;
  width: 164px;
  height: 102px;

  opacity: 0.5;
  border-radius: 50%;
  z-index: -1;
}
.section-1-footer-bg.white {
  background-color: #fff;
  left: 120px;
  top: 12px;
}
.section-1-footer-bg.red {
  background-color: var(--danger);
  right: 120px;
  top: 12px;
}
.section-1-footer-bg.yellow {
  background-color: var(--tint-color);
  width: 108px;
  height: 108px;
}

.section-1-footer {
  padding: 0 60px;
  border-radius: 40px;
  background: rgba(27, 27, 27, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(80px);
  display: flex;
  align-items: center;
}

.section-1-subtitle {
  margin-top: 24px;
  font-size: clamp(16px, 4vw, 24px);
  line-height: clamp(24px, 4vw, 36px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}


.section-1-footer-item {
  flex: 1;
  text-align: center;
}

.section-1-footer-separator {
  width: 180px;
  height: 180px;
  background: url("./big_plus.svg") no-repeat center center / 100%;
  margin: 0 16px;
}

.section-1-logo {
  padding: 0 32px 0 24px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: rgba(255, 255, 255, 0.05);
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 32px;
}

.section-1-logo img {
  height: 32px;
}

.section-1 .circle-bg-red {
  width: 400px;
  height: 300px;
  left: 0;
  top: -40px;
}

.section-1 .circle-bg-yellow {
  width: 500px;
  height: 400px;
  top: -140px;
  left: 70px;
}

@media only screen and (max-width: 991px) {
  .title-smile {
    height: 27px;
    width: 27px;
  }

  .section-1-footer-wrap {
    margin-top: 48px;
    border-radius: 24px;
  }
  .section-1-footer {
    flex-direction: column;
    padding: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
  }

  .section-1-footer-separator {
    /*display: none;*/
    height: 40px;
    width: 120px;
    margin: 24px 0;
  }

  .section-1-footer-bg.white {
    left: 2px;
  }
  .section-1-footer-bg.red {
    right: 2px;
    top: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .section-1-footer-bg.red {
    top: auto;
    bottom: 2px;
  }

}
@media only screen and (max-width: 575px) {
  .section-1-text {
    font-size: 14px;
    line-height: 20px;
  }
  .section-1 .circle-bg-red {
    left: -100px;
  }
  .section-1  .circle-bg-yellow {
    left: -200px;
    top: -200px;
  }
}