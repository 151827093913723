@font-face {/*400*/
  font-family: 'NTSomic-Regular';
  src: url('../fonts/NTSomic-Regular.eot');
  src: url('../fonts/NTSomic-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/NTSomic-Regular.woff') format('woff'),
  url('../fonts/NTSomic-Regular.ttf') format('truetype'),
  url('../fonts/NTSomic-Regular.svg#webfont') format('svg');
}

@font-face {/*600*/
  font-family: 'NTSomic-Medium';
  src: url('../fonts/NTSomic-Medium.eot');
  src: url('../fonts/NTSomic-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/NTSomic-Medium.woff') format('woff'),
  url('../fonts/NTSomic-Medium.ttf') format('truetype'),
  url('../fonts/NTSomic-Medium.svg#webfont') format('svg');
}

@font-face {/*700*/
  font-family: 'NTSomic-Bold';
  src: url('../fonts/NTSomic-Bold.eot');
  src: url('../fonts/NTSomic-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/NTSomic-Bold.woff') format('woff'),
  url('../fonts/NTSomic-Bold.ttf') format('truetype'),
  url('../fonts/NTSomic-Bold.svg#webfont') format('svg');
}


/* -------------------------- */
