.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
}
.form-group.error .form-control{
  border-color: var(--danger);
}
.form-group label, .form-control-label {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 4px;
  font-family: var(--font-bold);

}

.form-control-label {
  display: flex;
  align-items: flex-end;
}

.form-control-icon {
  position: absolute;
  left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control {
  width: 100%;
  padding: 0 12px;
  color: var(--text-color);
  min-height: 48px;
  position: relative;
  transition: var(--transition);
  font-family: var(--font-medium);
  font-size: 16px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
}

select.form-control {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  padding-right: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-control::placeholder {
  transition: var(--transition);
  font-size: 16px;
  font-family: var(--font-refular);
}

.form-control:focus {
  color: #fff;
  outline: 0;
  box-shadow: none;
  border-color: var(--tint-color);
  background: rgba(255, 255, 255, 0.05);
}

.form-control-icon {
  padding-left: 40px;
}

.form-group-error {
  position: absolute;
  right: 0;
  color: var(--danger);
  font-size: 16px;
}

textarea.form-control {
  resize: none;
  padding-top: 16px;
  padding-bottom: 16px;
  min-height: 124px;
}

.form-control-pass-eye {
  cursor: pointer;
  position: absolute;
  right: 16px;
}

.form-password-forgot {
  margin-right: 16px;
  cursor: pointer;
  font-size: 14px;
  color: var(--muted-color);
  transition: var(--transition);
}

.form-password-forgot:hover {
  text-decoration: underline;
}

select.form-control[name= ''] {
  font-family: var(--font-refular);
  font-size: 16px;
  color: var(--muted-color);
}

input.form-control[type= 'color'] {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  padding: 0;
  opacity: 0;
}

.form-control-group {
  display: flex;
  align-items: flex-end;
}

.form-control-group .form-control {
  border-right-width: 0;
  border-radius: 0;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.form-input-placeholder {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  font-size: 16px;
  font-family: var(--font-refular);
  color: rgba(255,255,255,0.3);
  left: 12px;
  top: 13px;
  transition: all .3s ease;
}

.form-group .form-control:empty + .form-input-placeholder {
  opacity: 1;
}

.form-group .form-control:focus + .form-input-placeholder,
.form-group.filled .form-input-placeholder,
.form-group .form-control:-webkit-autofill + .form-input-placeholder {
  transform: translateY(-40px);
  font-size: 14px;
}
.form-group .form-control:focus::placeholder,
.form-group.filled .form-control::placeholder,
.form-group .form-control:-webkit-autofill::placeholder{
  opacity: 1;
}

@media only screen and (max-width: 992px) {

}
.form-group select.form-control {
  border-radius: 60px;
  background: url("../../assets/images/icons/caret.svg") no-repeat calc(100% - 16px) center;
}
option {
  background-color: var(--main-bg-color);
}