.btn.loading .btn-content,
.btn.disabled .btn-content {
  opacity: 0.2;
}

.btn.loading,
.btn.disabled {
  pointer-events: none;
}

.circle-btn {
  min-width: 120px;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--tint-color);
  padding: 2px;
  cursor: pointer;
  transition: var(--transition);
  background-color: transparent;
}

.circle-btn-content {
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.circle-btn-text {
  min-width: 180px;
  text-align: left;
  font-family: var(--font-ermilov);
  font-size: 24px;
  line-height: 24px;
  max-width: 180px;
  color: #fff;
  position: absolute;
  left: 100%;
}

.circle-btn:focus, .circle-btn:focus .circle-btn-content {
  background-color: transparent;
}

.circle-btn:active:focus, .circle-btn:active:focus .circle-btn-content {
  background-color: var(--tint-color);
}

.circle-btn:hover .circle-btn-content {
  background-color: rgba(131, 192, 0, 0.1);
}
.circle-btn-arrow-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  padding-left: 20px;
}
.circle-btn-arrow {
  transition: var(--transition);
  transform: translateX(0);
}
.circle-btn:hover .circle-btn-arrow, .circle-btn:active:focus .circle-btn-arrow {
  transform: translateX(12px);
}