header {
  height: 100px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
}

.header {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: var(--transition);
}

header button {
}

header.scrolled {
  background: rgba(20, 22, 30, 0.4);
  backdrop-filter: blur(20px);
}

header.scrolled .header {
  border-bottom-color: #fff;
}

.header-logo {
  width: 224px;
  height: auto;
  transition: transform .5s ease;
  cursor: pointer;
}


.header-right, .header-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

nav {
  margin: 0 -12px;
}

nav ul {
  list-style: none;
  display: flex;
  font-size: 14px;
}

nav ul li {
  padding-bottom: 0;

}

nav a {
  font-size: 14px;
  font-family: var(--font-medium);
  line-height: 18px;
  color: rgba(255, 255, 255, 0.3);
  margin: 0 12px;
  transition: var(--transition);
  white-space: nowrap;
}

nav a.active {
  color: var(--text-color);
}

.header-lang-select {
  margin-left: 20px;
  border-radius: 24px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: var(--font-medium);
  color: var(--main-bg-color);
  cursor: pointer;
  transition: var(--transition);
  border: 1px solid #fff;
  width: 78px;
  min-width: 78px;
  height: 48px;
  background-color: transparent;
  display: flex;
}

.header-lang-select-value {
  flex: 1;
  position: relative;
  border-radius: 24px;
  transition: var(--transition);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  color: #fff;
  text-transform: uppercase;
  padding: 0 12px 0 24px;
}

.header-lang-select:hover, .header-lang-select.visible {
  background-color: #424242;
}


.header-lang-select-hidden {
  position: absolute;
  top: 20px;
  left: -1px;
  right: -1px;
  padding-top: 18px;
  color: rgba(255, 255, 255, 0.3);
  background: #424242;
  border-radius: 0 0 24px 24px;
  display: none;
  text-transform: uppercase;
  border: 1px solid #fff;
  border-top: none;
}


.header-lang-select.visible .header-lang-select-hidden {
  display: block;
}


.header-lang-select-hidden-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: var(--transition);
  width: 100%;
  padding: 8px 8px 8px 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

.header-lang-select-hidden-item:hover {
  color: var(--tint-color);
}

.header-menu {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.header-right > * {
  margin-left: 20px;
}

.menu-burger {
  cursor: pointer;
  transition: var(--transition);
  border-radius: 50%;
  background: url("../../assets/images/icons/burger.svg") no-repeat center center;
  background-color: rgba(255, 255, 255, 0.1);
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  display: none;
}

.menu-burger:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.header-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 40px;
}

.hidden-menu {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(20, 20, 20, 0.8);
  backdrop-filter: blur(20px);
  z-index: 100;
  padding: 16px;
  padding-top: 160px;
  transform: translateX(-100%);
  transition: var(--transition);
  display: none;
  flex-direction: column;

}
.hidden-menu.visible {
  transform: translateX(0);
}
.hidden-menu.visible + header {
  background: transparent;
}

.hidden-menu.visible + header .menu-burger {
  background: url("../../assets/images/close.svg") no-repeat center center;
  background-color: rgba(255, 255, 255, 0.1);
}
.hidden-menu nav {
  margin: 0;
  flex: 1;
  align-items: center;
  display: flex;
  margin-bottom: 24px;
}
.hidden-menu nav ul{
  flex-direction: column;
}
.hidden-menu nav a {
  margin: 16px 0;
  padding: 16px 32px 16px 0;
  display: inline-block;
  color: var(--tint-color);
  background: url("../../assets/images/icons/arrow-watch.svg") no-repeat right center;
  transition: none;
}
.hidden-menu nav a.active {
  color: #fff;
  background: none;
}
